<template>
  <div class='contcat'>
    <img class="banner" src="../assets/img/contcat.png">
    <div class="content">
      <img class="left" src="../assets/img/contcat1.png">
      <div class="right">
        <div class="top">
          <div class="title">商务合作</div>
          <img class="more" src="../assets/img/contcat3.png">
        </div>
        <div class="email">
          <p>消费品厂商： 13592682618@139.com</p>
          <p>生活服务商：13523000310@126.com</p>
          <p>特色农产品：778184373@qq.com</p>
          <p>媒体报道：1364148288@qq.com</p>
          <p>政府监管：2956606373@qq.com</p>
          <p>维权投诉：1364148288@qq.com</p>
          <p>用户关系：3695096874@qq.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.contcat {
  width: 1280px;
  margin: 0 auto;

  .banner {
    width: 1280px;
    height: 300px;
    margin: 40px 0;
  }

  .content {
    display: flex;

    .left {
      width: 570px;
      height: 390px;
    }

    .right {
      flex: 1;
      height: 375px;
      margin-top: 60px;
      padding-left: 50px;
      background: url(../assets/img/contcat2.png) no-repeat;
      background-position: 100%;
      background-size: cover;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: flex-end;
        transform: translate(-80px, -32px);

        .title {
          position: relative;
          width: calc(252px - 64px);
          height: 64px;
          line-height: 64px;
          color: #fff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          background-color: #1758D5;

          &::before {
            content: '';
            height: 0px;
            width: 0px;
            position: absolute;
            left: -64px;
            top: 0;
            border-top: 64px solid #1758D5;
            border-left: 64px solid transparent;
            border-right: 64px solid transparent;
            border-bottom: 64px solid transparent;
          }

          &::after {
            content: '';
            height: 0px;
            width: 0px;
            position: absolute;
            right: -64px;
            top: -64px;
            border-top: 64px solid transparent;
            border-left: 64px solid transparent;
            border-right: 64px solid transparent;
            border-bottom: 64px solid #1758D5;
          }
        }

        .more {
          width: 50px;
          height: 14px;
          margin-left: 65px;
        }
      }

      .email {
        color: #000;
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}
</style>
